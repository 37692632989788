import React, { useEffect } from 'react';
import "./Ads.css";

function GoogleFacebookAds() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="google-facebook-ads-container">
      <header className="ads-header">
        <div className="ads-header-content">
          <h1>Google and Meta Ads</h1>
        </div>
      </header>

      <div className="intro">
        <p>
          In today’s competitive digital landscape, running effective paid ad campaigns is the fastest way to reach your audience, drive traffic, and achieve measurable business growth. At Saptam Technologies, we specialize in creating high-performance Google and Meta Ads campaigns tailored to meet your unique business goals. With a data-driven approach and cutting-edge tools, we ensure your ad budget is utilized to its maximum potential, delivering exceptional ROI while keeping your brand ahead of the competition.
        </p>
      </div>

      <div className="why-invest">
        <h2>Why Invest in Google and Meta Ads?</h2>
        <p>📊 The Numbers Speak for Themselves:</p>
        <ul>
          <li>Google Ads can yield a 200% ROI for businesses, according to WordStream.</li>
          <li>Over 2.96 billion people actively use Meta platforms (Facebook and Instagram), making them an unparalleled space for audience targeting.</li>
          <li>Paid ads contribute to 65% of clicks on high-intent search terms.</li>
        </ul>
        <p>🌐 Unmatched Reach: Google Ads enable you to appear at the top of search results, while Meta Ads connect you with billions of users on Facebook and Instagram.</p>
        <p>📈 Scalable Results: Whether you’re aiming for brand awareness, lead generation, or sales, paid advertising offers a scalable and predictable way to achieve your goals.</p>
      </div>

      <div className="ads-image">
        <img src="/google-ads.webp" alt="Google Ads" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
      </div>

      <div className="google-ads-services">
        <h2>Our Google Ads Services</h2>
        <ul>
          <li><strong>Search Ads:</strong> Be seen by customers actively searching for your products or services. We create keyword-optimized, high-ROI search campaigns to position you at the top of Google results.</li>
          <li><strong>Display Ads:</strong> Capture attention with visually engaging ads across Google’s extensive Display Network, reaching users on over 2 million websites and apps.</li>
          <li><strong>Shopping Ads:</strong> Drive e-commerce sales by showcasing your products directly in Google’s shopping results with detailed product descriptions and pricing.</li>
          <li><strong>YouTube Ads:</strong> Leverage YouTube’s massive audience with compelling video ads that engage and convert viewers into customers.</li>
          <li><strong>Remarketing Campaigns:</strong> Re-engage visitors who didn’t convert the first time by strategically displaying your ads as they browse the web.</li>
        </ul>
      </div>

      <div className="ads-image">
        <img src="/meta-ads.webp" alt="Meta Ads" style={{ width: '100%', height: 'auto', marginTop: '20px' }} />
      </div>

      <div className="meta-ads-services">
        <h2>Our Meta Ads Services</h2>
        <ul>
          <li><strong>Facebook Ads:</strong> Target your ideal customers with precision using Facebook’s advanced audience segmentation, including demographics, behaviors, and interests.</li>
          <li><strong>Instagram Ads:</strong> Visually stunning campaigns designed to engage users on Instagram, helping your brand stand out in a fast-paced, image-driven platform.</li>
          <li><strong>Lead Generation Ads:</strong> Simplify the process of collecting customer information with easy-to-use forms directly within Facebook and Instagram.</li>
          <li><strong>Retargeting Ads:</strong> Reconnect with users who’ve interacted with your website, social profiles, or past ads, keeping your brand top of mind.</li>
        </ul>
      </div>

      <div className="how-we-work">
        <h2>How We Work</h2>
        <ul>
          <li><strong>Strategic Planning:</strong> We start by understanding your business goals, target audience, and competitive landscape. This enables us to design campaigns tailored to deliver measurable results.</li>
          <li><strong>Campaign Creation:</strong> Using in-depth keyword research, compelling ad copy, and creative visuals, we craft ads that resonate with your audience.</li>
          <li><strong>Precise Targeting:</strong> Our expertise in Google and Meta’s advanced targeting capabilities ensures your ads are shown to the right people at the right time.</li>
          <li><strong>Performance Tracking and Optimization:</strong> We continuously monitor key performance indicators (KPIs) such as click-through rates (CTR), cost-per-click (CPC), and return on ad spend (ROAS). Adjustments are made to optimize campaign performance.</li>
        </ul>
      </div>

      <div className="why-choose-us">
        <h2>Why Choose Saptam Technologies for Google and Meta Ads?</h2>
        <ul>
          <li><strong>Certified Experts:</strong> Our team comprises certified Google Ads and Meta Ads professionals who are proficient in managing diverse campaigns.</li>
          <li><strong>Data-Driven Decisions:</strong> We leverage analytics and insights to fine-tune your campaigns for maximum performance.</li>
          <li><strong>Customized Strategies:</strong> No cookie-cutter solutions here. Each campaign is designed to align with your business objectives and audience behavior.</li>
          <li><strong>Transparent Reporting:</strong> You’ll receive detailed, easy-to-understand reports showcasing campaign performance, insights, and recommendations for improvement.</li>
        </ul>
      </div>

      <div className="proven-success">
        <h2>Proven Success with Google and Meta Ads</h2>
        <p>💡 Client Success Story: An e-commerce client saw a 35% increase in sales within three months of launching a combined Google and Meta Ads strategy. With an optimized funnel and retargeting efforts, their ROAS improved by 4x.</p>
      </div>

      <div className="cta">
        <h2>Ready to Boost Your Business with Paid Ads?</h2>
        <p>At Saptam Technologies, we’re committed to helping your business grow through innovative and results-oriented paid advertising campaigns. Let us handle the complexities of Google and Meta Ads while you focus on what you do best—running your business.</p>
        <p><strong style={{ color: '#2980b9' }}>📩 Contact us today to discuss how we can elevate your brand!</strong></p>
        <a
          href="https://wa.me/918306060312"
          target="_blank"
          rel="noopener noreferrer"
          className="quotation-button"
        >
          Get Quotation
        </a>
      </div>
    </div>
  );
}

export default GoogleFacebookAds;
