import React, { useEffect } from 'react';
import './Social.css';

function SocialMediaManagement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="social-media-container">
      <div className="section">
        <h1>Social Media Management</h1>
        <p>
          In a world where over 4.9 billion people use social media, having a strong presence on platforms like Facebook, Instagram, LinkedIn, and Twitter isn’t optional—it’s essential. 
          At Saptam Technologies, we offer comprehensive Social Media Management services designed to elevate your brand, engage your audience, and drive measurable results.
          Whether you’re looking to grow your followers, increase engagement, or generate leads, our team of experts ensures your social media strategy aligns with your business goals.
        </p>
      </div>

      <div className="section">
        <h2>Why Social Media Management Matters</h2>
        <div className="subsection">
          <p>📊 Social Media by the Numbers:</p>
          <ul>
            <li>71% of consumers who had a positive social media experience with a brand are likely to recommend it to others (Sprout Social).</li>
            <li>Over 90% of marketers say social media has increased exposure for their brand (Statista).</li>
            <li>Posts with visuals get 650% more engagement than text-only posts.</li>
          </ul>
        </div>

        <div className="subsection">
          <p>🌐 Unparalleled Reach:
            Social media platforms connect you to billions of users, offering endless opportunities to find and engage your target audience.
          </p>
        </div>

        <div className="subsection">
          <p>🎯 Cost-Effective Branding:
            Social media is a low-cost way to build your brand identity, strengthen customer relationships, and drive consistent sales.
          </p>
        </div>
      </div>

      <div className="section">
        <h2>Our Social Media Management Services</h2>
        <div className="subsection">
          <p>1. Social Media Strategy Development:
            We start by analyzing your industry, competitors, and target audience to create a customized strategy that meets your goals.
          </p>
        </div>

        <div className="subsection">
          <p>2. Content Creation and Curation
            Our team produces high-quality, engaging content, including:
            <ul>
              <li>Eye-catching visuals (photos, videos, and infographics).</li>
              <li>Captivating captions and hashtags for SEO optimization.</li>
              <li>Industry-relevant articles and user-generated content.</li>
            </ul>
          </p>
        </div>

        <div className="subsection">
          <p>3. Platform Management
            We handle all aspects of your social profiles, ensuring they are active, optimized, and engaging:
            <ul>
              <li>Regular posting schedules.</li>
              <li>Community management (responding to comments, messages, and reviews).</li>
              <li>Profile optimization for discoverability.</li>
            </ul>
          </p>
        </div>

        <div className="subsection">
          <p>4. Social Media Advertising
            Amplify your reach and results with targeted social media ads:
            <ul>
              <li>Audience segmentation based on demographics, interests, and behaviors.</li>
              <li>Campaign creation, monitoring, and optimization for maximum ROI.</li>
            </ul>
          </p>
        </div>

        <div className="subsection">
          <p>5. Analytics and Reporting
            We track and analyze key performance indicators (KPIs) such as engagement rates, follower growth, and website traffic to measure campaign success.
          </p>
        </div>
        <img src="/social.png" alt="Social Media Strategy" />
      </div>

      <div className="section">
        <h2>How We Work</h2>
        <div className="subsection">
          <p>1. Understanding Your Brand:
            We start with an in-depth consultation to understand your brand voice, goals, and audience preferences.
          </p>
        </div>

        <div className="subsection">
          <p>2. Crafting Unique Strategies:
            Every brand is unique, so we design personalized strategies that reflect your identity and align with your objectives.
          </p>
        </div>

        <div className="subsection">
          <p>3. Engaging and Growing Your Audience:
            Through engaging content and proactive communication, we build strong connections with your audience.
          </p>
        </div>

        <div className="subsection">
          <p>4. Continuous Monitoring and Optimization:
            Social media trends evolve quickly. We monitor your campaigns and adapt to keep your brand relevant and effective.
          </p>
        </div>
        <img src="/social2.webp" alt="Social Media Engagement" />
      </div>

      <div className="section">
        <h2>Benefits of Professional Social Media Management</h2>
        <ul>
          <li>Save Time: Focus on your business while we handle your social media presence.</li>
          <li>Build Brand Loyalty: Strengthen connections with your audience through consistent, authentic engagement.</li>
          <li>Increase Website Traffic: Drive clicks and conversions with strategic posts and campaigns.</li>
          <li>Stay Ahead of Trends: Leverage the latest social media features and trends to keep your brand fresh and relevant.</li>
        </ul>
      </div>

      <div className="section">
        <h2>Why Choose Saptam Technologies for Social Media Management?</h2>
        <ul>
          <li>Experienced Team: Our social media specialists understand platform-specific nuances, ensuring your campaigns resonate across channels.</li>
          <li>Tailored Approach: We create strategies tailored to your brand, audience, and goals—no generic solutions here.</li>
          <li>Creative Excellence: From stunning visuals to engaging captions, our creative team ensures your content stands out.</li>
          <li>Proven Results: Our clients have seen significant improvements in brand awareness, engagement, and conversions.</li>
        </ul>
      </div>

      <div className="section">
        <h2>💡 Client Success Story:</h2>
        <p>
          A retail client increased their Instagram engagement by 85% and achieved a 3x increase in sales within six months of implementing our tailored social media strategy.
        </p>
      </div>

      <div className="section">
        <h2>Platforms We Manage</h2>
        <ul>
          <li>Facebook</li>
          <li>Instagram</li>
        </ul>
      </div>

      <div className="last">
        <h2>Get Started Today</h2>
        <p>
          At Saptam Technologies, we’re passionate about helping businesses thrive on social media. Let us turn your profiles into powerful marketing tools that deliver real results.
        </p>
        <p><strong style={ {color: '#2980b9' }}>📩 Contact us today to discuss your social media goals!</strong></p>
        <a
          href="https://wa.me/918306060312"
          target="_blank"
          rel="noopener noreferrer"
          className="quotation-button"
        >
          Get Quotation
        </a>
      </div>
    </div>
  );
}

export default SocialMediaManagement;
