import React, { useEffect } from 'react';
import './terms.css';

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Terms and Conditions</h1>
      </header>

      <div className="terms-section" id="intro">
        <h2>1. Introduction</h2>
        <p>
          Welcome to Saptam Technologies! These Terms and Conditions govern your use of our website, www.saptamtech.com, and any services or products provided by Saptam Technologies. By accessing or using the Website, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from using the Website.
        </p>
      </div>

      <div className="terms-section" id="use">
        <h2>2. Use of the Website</h2>
        <h3>2.1 Eligibility</h3>
        <p>
          By using this Website, you affirm that you are at least 18 years old or have the legal authority to enter into these Terms on behalf of a minor.
        </p>
        <h3>2.2 License</h3>
        <p>
          We grant you a limited, non-exclusive, and non-transferable license to access and use the Website for personal and non-commercial purposes, subject to these Terms.
        </p>
        <h3>2.3 Account Creation</h3>
        <p>
          Some areas of the Website may require you to create an account. If you choose to create an account, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </p>
      </div>

      <div className="terms-section" id="services">
        <h2>3. Services</h2>
        <h3>3.1 Service Offerings</h3>
        <p>
          Saptam Technologies offers web development, digital marketing, branding, and other related services. These services may be subject to additional terms, which will be outlined in separate agreements.
        </p>
        <h3>3.2 Service Availability</h3>
        <p>
          We strive to ensure the availability of our services, but we do not guarantee uninterrupted or error-free service. We may update, modify, or suspend services as needed.
        </p>
      </div>

      <div className="terms-section" id="intellectual-property">
        <h2>4. Intellectual Property</h2>
        <h3>4.1 Ownership</h3>
        <p>
          All content, designs, logos, trademarks, and other intellectual property on the Website are owned by or licensed to Saptam Technologies. You may not use, copy, reproduce, or distribute any content without our prior written consent.
        </p>
        <h3>4.2 User-Generated Content</h3>
        <p>
          By submitting any content (e.g., reviews, testimonials, images, etc.) to our Website, you grant us a worldwide, royalty-free, and perpetual license to use, modify, and publish such content.
        </p>
      </div>

      <div className="terms-section" id="privacy-policy">
        <h2>5. Privacy Policy</h2>
        <p>
          Your use of our Website is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy for more details.
        </p>
      </div>

      <div className="terms-section" id="prohibited-conduct">
        <h2>6. Prohibited Conduct</h2>
        <p>You agree not to:</p>
        <ul>
          <li>6.1 Violate any laws or regulations</li>
          <li>6.2 Impersonate any person or entity</li>
          <li>6.3 Engage in harmful, deceptive, or fraudulent activity</li>
          <li>6.4 Attempt to hack or disrupt the Website</li>
          <li>6.5 Use the Website for any illegal or unauthorized purposes</li>
        </ul>
      </div>

      <div className="terms-section" id="third-party-links">
        <h2>7. Third-Party Links</h2>
        <p>
          The Website may contain links to third-party websites or resources. We are not responsible for the content or practices of these third parties. Your use of third-party websites is at your own risk.
        </p>
      </div>

      <div className="terms-section" id="disclaimers">
        <h2>8. Disclaimers</h2>
        <h3>8.1 No Warranty</h3>
        <p>
          Saptam Technologies provides the Website and services on an "as is" and "as available" basis. We do not warrant that the Website will be error-free, secure, or free of viruses.
        </p>
        <h3>8.2 Limitation of Liability</h3>
        <p>
          To the fullest extent permitted by law, Saptam Technologies will not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Website or services.
        </p>
      </div>

      <div className="terms-section" id="indemnification">
        <h2>9. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Saptam Technologies, its affiliates, employees, and agents from any claims, losses, or damages arising out of your use of the Website, violation of these Terms, or infringement of third-party rights.
        </p>
      </div>

      <div className="terms-section" id="modifications">
        <h2>10. Modifications to the Terms</h2>
        <p>
          Saptam Technologies reserves the right to modify or update these Terms at any time. We will notify you of any changes by updating the date at the bottom of this page. Your continued use of the Website after such changes constitutes your acceptance of the updated Terms.
        </p>
      </div>

      <div className="terms-section" id="termination">
        <h2>11. Termination</h2>
        <p>
          We may suspend or terminate your access to the Website and services if you violate these Terms or engage in prohibited conduct. Upon termination, all rights and obligations will cease except for those that are intended to survive termination.
        </p>
      </div>

      <div className="terms-section" id="governing-law">
        <h2>12. Governing Law</h2>
        <p>
          These Terms will be governed by and construed in accordance with the laws of India. Any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts in India.
        </p>
      </div>

      <div className="terms-section" id="contact">
        <h2>13. Contact Information</h2>
        <p>
          If you have any questions about these Terms or our services, please contact us at:
        </p>
        <p>Saptam Technologies</p>
        <p>Sector 1, Malviya Nagar, Jaipur, India, 302017</p>
        <p>Email: <a href="mailto:info@saptamtech.com">info@saptamtech.com</a></p>
        <p>Phone: +91 8306060312</p>
      </div>

      <div className="terms-section" id="last-updated">
        <h2>14. Last Updated</h2>
        <p>These Terms and Conditions were last updated on 19/11/2024.</p>
      </div>
    </div>
  );
}

export default Terms;
