import React from 'react';
import './Footer.css';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for internal routing
import { MdEmail, MdLocationOn, MdPhone, MdCopyright } from 'react-icons/md';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section contact-message">
          <h3>We'd Love to Hear From You!</h3>
          <p>
            At Saptam Technologies, we craft stunning websites, eye-catching graphics, and run effective Google/Facebook ads to boost your business. Let's work together to create an impactful online presence. <span>Contact us today!</span>
          </p>
        </div>

        <div className="footer-section our-services">
          <h4>Services</h4>
          <ul>
            <li>
              <RouterLink to="/website-development">
                Website Development
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/google-facebook-ads">
                Google & Meta Ads
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/social-media-management">
                Social Media Management
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/graphic-designing">
              Graphic Designing
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/ecommerce-development">
              E-commerce Development
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/answer-engine-optimization">
              Answer Engine Optimization
              </RouterLink>
            </li>
          </ul>
        </div>

        <div className="footer-section quick-links">
          <h4>Resources</h4>
          <ul>
            <li>
              <RouterLink to="/blog">Blogs</RouterLink>
            </li>
            <li>
              <RouterLink to="/pricing">Pricing</RouterLink>
            </li>
          </ul>
        </div>

        {/* Organization Details */}
        <div className="footer-section organization-details">
          <h4>Saptam Technologies</h4>
          <p>
            <a href="mailto:info@saptamtech.com">
              <MdEmail /> info@saptamtech.com
            </a>
          </p>
          <p><MdPhone /> +91 8306060312
          </p>
          <p><MdLocationOn /> Sector 1, Malviya Nagar, Jaipur, India, 302017</p>

          {/* New section for icons with links */}
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61567117079715&mibextid=JRoKGi"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link facebook"
            >
              <img
                src="/facebook.webp"
                alt="Facebook"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              />
            </a>
            <a
              href="https://www.instagram.com/saptam_technologies/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link instagram"
            >
              <img
                src="/instagram.webp"
                alt="Instagram"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/saptam-technologies"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link linkedin"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 72 72" id="linkedin">
                <g id="providers-list" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                  <g id="linkedin">
                    <rect id="Rectangle-2" width="72" height="72" x="0" y="0" fill="#117EB8" rx="4"></rect>
                    <path
                      id="Shape"
                      fill="#FFF"
                      d="M13.139 27.848h9.623V58.81h-9.623V27.848zm4.813-15.391c3.077 0 5.577 2.5 5.577 5.577 0 3.08-2.5 5.581-5.577 5.581a5.58 5.58 0 1 1 0-11.158zm10.846 15.39h9.23v4.231h.128c1.285-2.434 4.424-5 9.105-5 9.744 0 11.544 6.413 11.544 14.75V58.81h-9.617V43.753c0-3.59-.066-8.209-5-8.209-5.007 0-5.776 3.911-5.776 7.95V58.81h-9.615V27.848z"
                    ></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>


      </div>

      <div className="footer-bottom">
        <div className="footer-links">
          {/* Use RouterLink to navigate without opening a new tab */}
          <RouterLink to="/terms">
            Terms & Conditions
          </RouterLink>
          <RouterLink to="/privacy-policy">
            Privacy Policy
          </RouterLink>
        </div>
        <div className="footer-copyright">
          <p>
            <MdCopyright style={{ marginRight: '4px' }} />
            {new Date().getFullYear()} Saptam Technologies
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
